import Component from 'vue-class-component';
import Vue, { computed } from 'vue';
import ConfirmationDialog from '@/components/alerts/ConfirmationDialog.vue';
import PickerOrderItemSection from '../_components/PickerOrderItemSection.vue';
import { VForm } from '@/utilities/form';
import PickerCompleteOrderDialog from '../_components/PickerCompleteOrderDialog.vue';
import DiscardItemDialog from '@/views/_components/DiscardItemDialog.vue';
import {
  dispatchPickerAction,
  PickerAction,
  PickerGetter,
  pickerNamespace,
  PickerState
} from '../_state/picker-module-type';
import {
  AmsLaneOnHandViewModel,
  OrderLineItemViewModel,
  AmsOrderLineStatusEnum,
  OrderListOutput,
  AmsOrderStatusEnum,
  OrderViewModel,
  PrinterLocationDTO,
  PickersClient,
  WarehousePrinterType,
  AmsOrderLineItemsClient
} from '@/api/api';
import { NavigationGuardNext, RawLocation, Route } from 'vue-router';
import NwdLocations from '@/router/nwd-router';
import { ISection } from '../_components/PickerOrderItemSection';
import { Validators } from '@/utilities/validators';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction, AlertNamespace, SnackbarMessage } from '@/store/alert/alert.module-types';
import { STATUS_PICKED } from '@/shared/constants/common';
import { DiscardItemDialogType } from '@/views/_components/DiscardItemDialog';
import { isIosSafari } from '@/utilities/utils';
import { OrderNamespace, OrderState } from '@/store/order/order-module-types';
import { getTopPriorityOrder } from '../picker-utils';

import { date } from '@/filters/date.filter';
import { Watch } from 'vue-property-decorator';

import { GetLaneReservationsCaseBottleText } from '@/utilities/lane-utils';
import SkuConfirmationDialog from '@/views/_components/scanner/SkuConfirmationDialog.vue';

@Component({
  name: 'OrderItemDetail',
  filters: {
    date
  },
  components: {
    ConfirmationDialog,
    PickerOrderItemSection,
    PickerCompleteOrderDialog,
    DiscardItemDialog,
    SkuConfirmationDialog
  }
})
export default class OrderQCDetail extends Vue {
  public readonly GetLaneReservationsCaseBottleText = GetLaneReservationsCaseBottleText;

  $refs!: Vue['$refs'] & {
    form: VForm;
    discardItemDialog: DiscardItemDialogType;
    // itemBottlesField: HTMLInputElement;
    // itemCasesField: HTMLInputElement;
  };
  @OrderNamespace.State(OrderState.ordersPicker)
  readonly ordersPicker!: OrderListOutput[];
  @pickerNamespace.Getter(PickerGetter.order)
  readonly order!: OrderViewModel;
  @pickerNamespace.Getter(PickerGetter.orderLineItem)
  readonly orderLineItem!: OrderLineItemViewModel;
  @pickerNamespace.Getter(PickerGetter.nextOrderLineItem)
  readonly nextOrderLineItem!: OrderLineItemViewModel;
  @pickerNamespace.State(PickerState.orderLineItemSections)
  readonly sections!: ISection[];

  STATUS_PICKED = STATUS_PICKED;
  completeOrderDialog = false;
  checkDiscardChanges = true;
  numberOfPallets: number | null = null;
  numberOfCases: string = '';
  numberOfBottles: string = '';

  warehousePrinters: PrinterLocationDTO[] = [];
  targetLocationCode: string | undefined = '';
  isLoadingPrinters: boolean = true;

  andGoNext: boolean = false;
  itemId: number = 0;

  lineItemInventory: AmsLaneOnHandViewModel[] = [];
  scannerConfirmationDialogVisible: boolean = false;
  readonly numberOfPalletsRules = Validators.for(
    '# of pallets',
    Validators.required,
    Validators.greaterThan(0, '# of pallets must be greater than 0.'),
    Validators.lessThan(100, '# of pallets must be less than 100.')
  );
  readonly numberOfCasesRules = Validators.for(
    '# of cases',
    Validators.required,
    Validators.pattern(RegExp('\\d'), () => 'Enter the case amount.')
  );
  readonly numberOfBottlesRules = Validators.for(
    '# of bottles',
    Validators.required,
    Validators.pattern(RegExp('\\d'), () => 'Enter the bottle amount".')
  );

  readonly printerSelectRules = Validators.for('Pallet Tag Printer', Validators.required);

  partNoSection: ISection = {
    icon: 'mdi-qrcode-scan',
    title: 'SKU',
    key: 'partNo',
    isConfirm: undefined
  };
  partNoModel = '';
  correctedLane: string = '';

  get wasLaneWrong(): boolean {
    // loop through rendered sections and check if the lane reservations section is wrong
    const laneReservationsSection = this.renderedSections.find((s) => s.key === 'laneReservations');

    if (!laneReservationsSection) {
      return false;
    }

    return laneReservationsSection.isConfirm === false;
  }

  get partNoMatch(): boolean {
    return this.partNoModel === this.orderLineItem.partNo;
  }
  get partNoCardColor(): string | undefined {
    if (!this.partNoModel) {
      return 'white';
    }

    return this.partNoMatch ? undefined : 'red lighten-5';
  }

  get sectionsCardColor(): string | undefined {
    if (!this.partNoMatch) {
      return 'white';
    }

    return undefined;
  }

  get orderCompleted(): boolean {
    return parseInt(this.order.status || '') == AmsOrderStatusEnum.Completed;
  }

  get lineItemIndex(): number {
    const idx = this.order.orderItems?.findIndex((li) => li.id === this.orderLineItem.id);
    return idx !== undefined ? idx + 1 : this.orderLineItem.itemNo;
  }

  get backToOrder(): RawLocation {
    const location = NwdLocations.picker.orderDetail.orderLineItems(this.order.id!);

    return location;
  }

  /** Checking if all the data points have been approved (green boxes). */
  get isReadyToComplete(): boolean {
    const sectionsValid = this.renderedSections.every(
      (s) => s.isConfirm || (s.key === 'laneReservations' && s.isConfirm != null)
    );

    const res = sectionsValid && !!this.partNoSection.isConfirm;
    return res;
  }

  get hasBeenPicked(): boolean {
    return this.orderLineItem.statusCode == AmsOrderLineStatusEnum.Picked;
  }

  get isTopPriorityOrder(): boolean {
    if (this.ordersPicker.length == 0) return false;
    const topPriorityOrder = getTopPriorityOrder(this.ordersPicker);
    return !topPriorityOrder || this.order.id != topPriorityOrder.orderId ? false : true;
  }

  get isFinalPick(): boolean {
    return this.nextOrderLineItem ? false : true;
  }

  get completeOrderDialogTitle(): string {
    return `Complete AMS Order #${this.order?.amsOrderId?.toFixed(1)}`;
  }

  get completeOrderDialogSubtitle(): string {
    return `${this.order?.picker?.name}`;
  }

  get renderedSections(): ISection[] {
    if (this.orderLineItem) {
      const renderedSections = this.sections.filter(
        (s) => s.key != null && this.orderLineItem[s.key] != null && this.orderLineItem[s.key] !== ''
      );
      return renderedSections;
    }

    return [];
  }

  get skippedSections(): ISection[] {
    if (this.orderLineItem) {
      return this.sections.filter((s) => this.orderLineItem[s.key] == null || this.orderLineItem[s.key] == '');
    }

    return [];
  }

  get progress(): number {
    if (!this.order) {
      return 0;
    }
    const done = this.order.orderItems?.filter((li) => li.statusCode == STATUS_PICKED)?.length ?? 0;
    return (done / this.order.lineItemCount) * 100;
  }

  get caseRules() {
    return (value: string) => {
      if (value === this.lineItemCases.toString()) {
        return true;
      } else {
        return "Must match the item's case count";
      }
    };
  }

  get bottleRules() {
    return (value: string) => {
      if (value === this.lineItemBottles.toString()) {
        return true;
      } else {
        return "Must match the item's bottle count";
      }
    };
  }

  get correctedLaneRules() {
    return (value: string) => {
      if (value == null || value == '') {
        return 'Please enter the corrected lane/s';
      } else {
        return true;
      }
    };
  }

  get lineItemCases() {
    return Math.trunc(this.orderLineItem?.quantity / this.orderLineItem?.unitsPerCase);
  }

  get lineItemBottles() {
    return this.orderLineItem?.quantity % this.orderLineItem?.unitsPerCase;
  }

  // the user wants to be at the top of the page when the order line item changes
  // they need to enter the SKU number into the box up there before they can do anything
  @Watch('orderLineItem', { immediate: true })
  async onOrderLineItemChanged(): Promise<void> {
    window.scrollTo(0, 0);
  }
  @AlertNamespace.Action(AlertAction.showSnackbar)
  readonly showSnackbar!: (payload: SnackbarMessage) => void;

  async mounted(): Promise<void> {
    this.fillUpPartNoValue(this.$route.query as { partNo: string });
    // this.reloadLineItemInventory();

    if (this.hasBeenPicked) {
      this.partNoModel = this.orderLineItem.partNo || '';
      this.partNoModelChange();

      if (this.hasBeenPicked) {
        this.sections.forEach((s) => (s.isConfirm = true));
      }
    }

    if (process.env.NODE_ENV === 'development') {
      this.partNoModel = this.orderLineItem.partNo || '';
      this.partNoModelChange();
    }

    await this.loadPrinters();
  }

  async loadPrinters(): Promise<void> {
    this.isLoadingPrinters = true;
    try {
      const pickersClient = new PickersClient();
      const newPrinters = await pickersClient.getPrintLocations(this.order.id, WarehousePrinterType.Label);
      const haveDefaultPrinter = newPrinters.some((p) => p.isDefault);
      if (!haveDefaultPrinter) {
        this.targetLocationCode = undefined;
      } else {
        this.targetLocationCode = newPrinters.find((p) => p.isDefault)?.locationCode;
      }

      newPrinters.unshift(
        new PrinterLocationDTO({
          locationCode: undefined,
          printerStatus: 'Do not print',
          isDefault: false,
          isOnline: true
        })
      );

      this.warehousePrinters = newPrinters;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingPrinters = false;
    }
  }

  partNoModelChange(): void {
    this.partNoSection.isConfirm = this.partNoMatch;
  }

  async reloadLineItemInventory() {
    if (!this.orderLineItem?.partNo) {
      return;
    }

    const pickersClient = new PickersClient();
    const inventory = await pickersClient.getInventoryOfLineItem(this.orderLineItem.partNo, this.order.locationCode);

    this.lineItemInventory = inventory;
  }

  // async beforeRouteLeave(
  //   _to: Route,
  //   _from: Route,
  //   next: NavigationGuardNext
  // ): Promise<void> {
  //   if (
  //     this.checkDiscardChanges &&
  //     this.sections.some((s) => s.isConfirm !== undefined)
  //   ) {
  //      this.$refs.discardItemDialog.show(next);
  //     return;
  //   }

  //   this.checkDiscardChanges = true;
  //   next();
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async beforeRouteUpdate(to: Route, _from: Route, next: NavigationGuardNext): Promise<void> {
    const { orderItemId } = to.params;
    const item = await dispatchPickerAction(PickerAction.getOrderLineItem, {
      orderLineItemId: parseInt(orderItemId)
    });

    await dispatchPickerAction(PickerAction.getNextOrderLineItem, {
      orderId: item.orderId,
      seq: item.itemNo
    });

    this.fillUpPartNoValue(to.query as { partNo: string });

    next();
  }

  async complete(next: boolean = false): Promise<void> {
    this.andGoNext = next;
    this.itemId = this.orderLineItem.id;
    this.showConfirmationDialog();
  }

  async completeAndGoNext(): Promise<void> {
    await dispatchPickerAction(PickerAction.pickOrderLineItem, {
      orderLineItemId: this.orderLineItem.id
    });

    if (!this.nextOrderLineItem) {
      return;
    }

    this.goToNextLineItem();
  }

  goToNextLineItem(): void {
    // annoyingly, this HAS to be a replace since a 'push' breaks the state of the page
    // WTF?!
    this.$router.replace(
      NwdLocations.picker.orderDetail.orderLineItem({
        orderId: this.nextOrderLineItem.orderId,
        orderItemId: this.nextOrderLineItem.id
      })
    );
  }

  async pick(): Promise<void> {
    await dispatchPickerAction(PickerAction.pickOrderLineItem, {
      orderLineItemId: this.orderLineItem.id
    });

    // prevent check discard changes
    this.checkDiscardChanges = false;
    await this.$router.push(NwdLocations.picker.orderDetail.orderLineItems(this.order.id!));
  }

  public async confirmationComplete(): Promise<void> {
    if (this.wasLaneWrong) {
      try {
        const amsOrderLineItemsClient = new AmsOrderLineItemsClient();
        await amsOrderLineItemsClient.correctLane(this.orderLineItem.id, this.correctedLane);
      } catch (error) {
        console.error(error);
        dispatchAlertAction(AlertAction.showError, 'Failed to correct lane.');
      }
    }

    if (!this.isFinalPick) {
      if (this.andGoNext) {
        await this.completeAndGoNext();
      } else {
        await this.pick();
      }
    } else {
      await this.submitCompleteFinal();
    }
    dispatchPickerAction(PickerAction.hideConfirmCompleteOrderDialog);
  }

  async submitCompleteFinal(): Promise<void> {
    await dispatchPickerAction(PickerAction.updateOrderNumberOfPallets, {
      orderId: this.order.id!,
      numberOfPallets: this.numberOfPallets ?? 0
    });
    await dispatchPickerAction(PickerAction.pickOrderLineItemAndFinalize, {
      orderLineItemId: this.orderLineItem.id
    });

    dispatchAlertAction(AlertAction.showSuccess, 'Pick completed.');

    // for whatever reason, this get's BROKEN. Idk why it's returning the full object...
    let targetLocationCode = this.targetLocationCode;
    if ((targetLocationCode as any)?.printerStatus != null) {
      targetLocationCode = (this.targetLocationCode as unknown as PrinterLocationDTO).locationCode;
    }

    if (targetLocationCode != null && targetLocationCode !== '') {
      console.log('Queueing pallet tag print job...', targetLocationCode);
      try {
        const pickersClient = new PickersClient();
        await pickersClient.queuePalletTagPrintJob(this.order.id, targetLocationCode);
      } catch (error) {
        console.error(error);
        dispatchAlertAction(AlertAction.showError, 'Failed to queue pallet tag print job.');
      }
    } else {
      console.warn('No target location code provided for pallet tag print job. Skipping print...');
    }

    // prevent check discard changes
    this.checkDiscardChanges = false;
    await this.$router.push(NwdLocations.picker.orders);
  }

  private showConfirmationDialog(): void {
    if (this.lineItemBottles !== null && this.lineItemCases !== null) {
      this.numberOfBottles = this.lineItemBottles === 0 ? '0' : '';
      this.numberOfCases = this.lineItemCases === 0 ? '0' : '';
    }

    dispatchPickerAction(PickerAction.showConfirmCompleteOrderDialog);
  }

  private fillUpPartNoValue(query: { partNo: string }): void {
    this.partNoModel = query.partNo;
    this.partNoModelChange();
  }

  public confirmScan(): void {
    this.scannerConfirmationDialogVisible = true;
  }

  public recievedSKU(event: string | null): void {
    if (event == null || event.trim() == '') {
      // ignore empty scans
      return;
    }

    if (event.trim() == this.orderLineItem.partNo) {
      this.showSnackbar({
        message: 'SKU confirmed!',
        timeout: 3000,
        color: 'success'
      });
    } else {
      this.showSnackbar({
        message: 'Scanned SKU did not match',
        timeout: 3000,
        color: 'error'
      });
    }

    this.partNoModel = event;
    this.partNoModelChange();

    this.scannerConfirmationDialogVisible = false;
  }
}
