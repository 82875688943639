import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import QuaggaWrapper from './QuaggaWrapper.vue';
import BarcodeScanner from './BarcodeScanner.vue';
import {
  AmsOrderLineItemModel,
  AuthResultViewModel,
  PickersClient,
  SkuScanReportViewModel,
  SkuScanStatusEnum,
} from '@/api/api';
import { AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';
import NwdLocations from '@/router/nwd-router';
import XExpandContainer from '@/components/_generics/x-expand-container.vue';
import SkuScanReportInfoCard from './SkuScanReportInfoCard.vue';

@Component({
  components: {
    QuaggaWrapper,
    BarcodeScanner,
    XExpandContainer,
    SkuScanReportInfoCard
  }
})
export default class SkuConfirmationDialog extends Vue {
  public readonly SkuScanStatusEnum = SkuScanStatusEnum;
  public readonly Math = Math;

  @Prop({ default: false }) readonly value!: boolean;

  @Watch('value', { immediate: true })
  onValueChanged(): void {
    this.visible = this.value;
  }

  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;

  @Emit('input')
  emitInput(newValue: boolean) {
    return newValue;
  }

  @Emit('SKU')
  emitSKU(newValue: string) {
    return newValue;
  }

  isFormValid = false;
  loadingNextLineItems = false;
  visible = false;
  activelyScanning = false;
  loadingSkuScanReport = false;
  showScanResultOverlay = false;
  scannerError: any = null;
  partNo: string | null = null;
  assignedOrderLineItems: AmsOrderLineItemModel[] = [];
  currentSkuScanReport: SkuScanReportViewModel | null = null;

  headers = [
    { text: 'AMS Order #', value: 'amsOrderId' },
    { text: 'Order Status', value: 'status' },
    { text: 'SKU', value: 'partNo' },
    { text: '# Cases', value: 'quantity' }
  ];

  get isNativeScannerSupported(): boolean {
    return 'BarcodeDetector' in window;
  }

  @Watch('visible', { immediate: true })
  onVisibleChanged(): void {
    if (this.visible) {
      this.activelyScanning = true;
      this.loadingNextLineItems = true;
    }

    this.emitInput(this.visible);
  }

  async onPartNoDetected(partNo: string) {
    if (partNo === null || partNo.trim() === '') {
      console.warn('Part number is empty, skipping event emission.');
      return;
    }

    this.showScanResultOverlay = true;
    this.activelyScanning = false;
    this.emitSKU(partNo);
  }

  onScannerError(error: any) {
    console.log('Scanner error', error);
    this.scannerError = error;
    this.showScanResultOverlay = true;
  }

  scanAgain() {
    this.showScanResultOverlay = false;
    this.activelyScanning = true;
  }

  goToScannedItem() {
    let location = null;

    if (
      !this.currentSkuScanReport ||
      !this.currentSkuScanReport.orderId ||
      !this.currentSkuScanReport.orderLineItemId
    ) {
      return;
    }
    location = NwdLocations.picker.orderDetail.orderLineItem({
      orderId: this.currentSkuScanReport.orderId,
      orderItemId: this.currentSkuScanReport.orderLineItemId,
      partNo: this.currentSkuScanReport.partNo
    });
    // check if we are already on the page
    if (
      this.$route.name === location.name &&
      this.$route.params.id === `${this.currentSkuScanReport.orderId}` &&
      this.$route.params.orderItemId === `${this.currentSkuScanReport.orderLineItemId}` &&
      this.$route.query.partNo === this.currentSkuScanReport.partNo
    ) {
      this.visible = false;
      return;
    }
    this.visible = false;
    if (location != null) {
      this.$router.push(location);
    }
  }

  close() {
    this.visible = false;
  }
}
