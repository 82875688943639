import { IOrderQCViewModel, OrderQCViewModel, AmsOrderStatusEnum, Timeframe, AuthResultViewModel } from '@/api/api';
import { date, dateTimeFilter } from '@/filters/date.filter';
import { Component, Vue } from 'vue-property-decorator';
import { QcAction, QcGetter, dispatchQcAction, qcNamespace } from '../_state/qc-module-type';
import NwdLocations from '@/router/nwd-router';
import moment from 'moment';
import { orderStatusString } from '@/filters/order-status-filter';
import { AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';

type OrderQCViewTableItem = IOrderQCViewModel & {
  isCompleted: boolean;
  isInProgress: boolean;
  isSentToBack: boolean;
};

@Component({
  filters: {
    date,
    dateTimeFilter,
    orderStatusString
  }
})
export default class QcOrderList extends Vue {
  get headers() {
    const tHeaders: any[] = [
      { text: 'AMS Order #', value: 'amsOrderId' },
      { text: 'Status', value: 'orderStatusCode' },
      { text: '# Cases', value: 'numberOfCases' },
      { text: '# Line Items', value: 'lineItemCount' },
      { text: 'Location', value: 'locationCode' },
      { text: 'Delivery Zone', value: 'deliveryZone' },
      { text: 'Added to Queue', value: 'addedToQueue' },
      { text: 'Storage Customer', value: 'wineryName' },
      { text: 'QC By', value: 'qCedByUserName' },
      { text: 'QC Completed', value: 'orderCompleteDate' },
      { text: 'Last Modified', value: 'lastModified' }
    ];

    if (this.$vuetify.breakpoint.smAndUp) {
      tHeaders.push({ value: 'action' });
    }

    return tHeaders;
  }

  @qcNamespace.Getter(QcGetter.orders)
  readonly orders!: OrderQCViewModel[];
  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;

  search = '';
  footerProps = {
    itemsPerPageOptions: [5, 10, 20, 30, -1]
  };
  options: {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
    groupBy: string[];
    groupDesc: boolean[];
    multiSort: boolean;
    mustSort: boolean;
  } = {
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: true,
    mustSort: false
  };

  isLoading = false;
  isTryingToQAOrderPickedByThemself = false;
  timeframe = Timeframe.PastWeek;
  timeframes = [
    { text: 'Today', value: Timeframe.Today },
    { text: 'Past week', value: Timeframe.PastWeek },
    { text: 'Past 30 days', value: Timeframe.Past30Days },
    { text: 'Past 60 days', value: Timeframe.Past60Days },
    { text: 'Past 90 days', value: Timeframe.Past90Days },
    { text: 'Past 6 months', value: Timeframe.Past6Months },
    { text: 'Past year', value: Timeframe.PastYear }
  ];

  get ordersWithInfo(): OrderQCViewTableItem[] {
    return this.orders.map((o) => ({
      ...o,
      isCompleted: o.orderStatusCode === AmsOrderStatusEnum.Completed,
      isInProgress: o.orderStatusCode === AmsOrderStatusEnum.InProgress,
      isSentToBack: o.orderStatusCode === AmsOrderStatusEnum.Returned
    }));
  }

  async mounted(): Promise<void> {
    this.isLoading = true;
    await dispatchQcAction(QcAction.updateOrderQcInput, {
      searchText: '',
      timeframe: this.timeframe
    });
    await dispatchQcAction(QcAction.getOrders);
    this.isLoading = false;
  }

  async refreshOrders(): Promise<void> {
    this.isLoading = true;
    await dispatchQcAction(QcAction.getOrders);
    this.isLoading = false;
  }

  async navigateToOrder(item: OrderQCViewModel): Promise<void> {
    if (this.credential?.userId == item.pickedByUserId)
    {
      this.isTryingToQAOrderPickedByThemself = true;
      return;
    }
    else {
      this.isTryingToQAOrderPickedByThemself = false;
    }
    console.log('item', item);
    const location = NwdLocations.qc.orderDetail.detail(item.id);
    await this.$router.push(location);
  }

  async updateOrderQcInput(value: { searchText: string; timeframe: number }): Promise<void> {
    await dispatchQcAction(QcAction.updateOrderQcInput, value);
  }

  getOrderIconColor(item: OrderQCViewTableItem): string {
    switch (item.orderStatusCode) {
      case AmsOrderStatusEnum.Completed:
        return 'success';
      case AmsOrderStatusEnum.QcInProgress:
        return 'primary';
      case AmsOrderStatusEnum.Returned:
        return 'red';
      default:
        return '';
    }
  }

  getOrderIcon(item: OrderQCViewTableItem): string {
    switch (item.orderStatusCode) {
      case AmsOrderStatusEnum.Completed:
        return 'mdi-check';
      case AmsOrderStatusEnum.QcInProgress:
        return 'mdi-check-circle-outline';
      case AmsOrderStatusEnum.Returned:
        return 'mdi-close-circle-outline';
      default:
        return 'mdi-timer-sand';
    }
  }

  customSort(items: IOrderQCViewModel[], sortBy: string[], sortDesc: boolean[]): IOrderQCViewModel[] {
    if (!sortBy || sortBy.length === 0) {
      return items;
    }

    const sort = sortBy[0];
    const sortD = sortDesc[0] ? -1 : 1;

    if (sort === 'orderStatusCode') {
      items = items.sort((a, b) => (a.orderStatusCode > b.orderStatusCode ? 1 : -1) * sortD);

      return items;
    }

    if (sort === 'id') {
      items = items.sort((a, b) => (a.id > b.id ? 1 : -1) * sortD);
    } else if (sort === 'numberOfCases') {
      items = items.sort((a, b) => (a.numberOfCases > b.numberOfCases ? 1 : -1) * sortD);
    } else if (sort === 'lineItemCount') {
      items = items.sort((a, b) => (a.lineItemCount > b.lineItemCount ? 1 : -1) * sortD);
    } else if (sort === 'addedToQueue') {
      items = items.sort((a, b) => {
        const res = (this.convertDate(a.addedToQueue) > this.convertDate(b.addedToQueue) ? 1 : -1) * sortD;
        return res;
      });
    } else if (sort === 'lastModified') {
      items = items.sort((a, b) => {
        const res = (this.convertDate(a.lastModified) > this.convertDate(b.lastModified) ? 1 : -1) * sortD;
        return res;
      });
    }

    const completedItems = items.filter((i) => i.orderStatusCode === AmsOrderStatusEnum.Completed);
    const otherItems = items.filter((i) => i.orderStatusCode !== AmsOrderStatusEnum.Completed);
    return [...otherItems, ...completedItems];
  }

  private convertDate(data: Date | undefined | null): Date {
    if (data instanceof Date) {
      return data;
    }

    return new Date(data ?? 0);
  }

  itemClassChecker(item: OrderQCViewTableItem) {
    return {
      'complete-status': item.isCompleted,
      'in-progress-status': item.isInProgress,
      'returned-status': item.isSentToBack,
      'black--text': true
    };
  }
}
